.skills__container {
  grid-template-columns: repeat(2, 360px);
  column-gap: 3rem;
  row-gap: 3rem;
  justify-content: center;
}

.skills__content {
  background-color: var(--container-color);
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 2rem 4rem;
  border-radius: 1.25rem;
}

.skills__title {
  font-size: var(--normal-font-size);
  font-weight: var(--font-medium);
  text-align: center;
  margin-bottom: var(--mb-1-5);
}

.skills__box {
  display: flex;
  justify-content: center;
  column-gap: 2.5rem;
}

.skills__group {
  display: grid;
  grid-template-columns: repeat(2, 140px);
  align-items: flex-start;
  row-gap: 1rem;
}

.skills__data {
  display: flex;
  column-gap: 1rem;
  margin: 0.2rem 0;
}

.skills svg {
  font-size: 1rem;
  color: var(--title-color);
}

.skills__name {
  font-size: var(--normal-font-size);
  font-weight: var(--font-medium);
  line-height: 18px;
}

.skills__level {
  font-size: var(--smaller-font-size);
}

/* BREAK POINTS */
@media screen and (max-width: 992px) {
  .skills__container {
    grid-template-columns: max-content;
    row-gap: 2rem;
  }
  .nav__container {
    margin-left: var(--mb-1-5);
    margin-right: var(--mb-1-5);
  }
}

@media screen and (max-width: 576px) {
  .skills__content {
    padding: 1.5rem;
  }

  .skills__container {
    grid-template-columns: repeat(1, 312px);
    column-gap: 3rem;
    row-gap: 3rem;
    justify-content: center;
  }

  .skills__box {
    padding-left: 1rem;
  }
}

@media screen and (max-width: 350px) {
  .skills__box {
    column-gap: 1.25rem;
  }

  .skills__name {
    font-size: var(--small-font-size);
  }
}
